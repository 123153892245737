@font-face {
  font-family: "GB";
  src: local("GB"), url(../../Fonts/Gilroy/Gilroy-Black.ttf) format("truetype");
}
@font-face {
  font-family: "GBO";
  src: local("GBO"), url(../../Fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GL";
  src: local("GL"), url(../../Fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GM";
  src: local("GM"), url(../../Fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "GR";
  src: local("GR"),
    url(../../Fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GSB";
  src: local("GSB"),
    url(../../Fonts/Gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "GT";
  src: local("GT"), url(../../Fonts/Gilroy/Gilroy-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "GUL";
  src: local("GUL"),
    url(../../Fonts/Gilroy/Gilroy-UltraLightItalic.ttf) format("truetype");
}

/* Landing Nav Bar Sign Up */
.navbar_light {
  /* background-color: #fff;
  padding-left: 3rem;
  padding-right: 3rem; */
}
.cendrol_log {
  width: 130px;
}
#home {
  /* margin-top: 6%; */
}
.land_nav_signup,
.land_nav_gett_app {
  box-sizing: border-box;
  border-radius: 4px;
  font-family: GSB;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #0e0e0e;
  outline: none;
  width: 135px;
  height: 46px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.land_nav_signup {
  border: 0.791146px solid #0e0e0e;
  width: 135px !important;
}

.land_nav_signup,
.land_nav_gett_app:focus {
  box-shadow: none !important;
}

.land_nav_gett_app {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.mob_app_popup {
  background: #fff1c6;
  border-radius: 5px;
}

.mob_app_close_btn {
  float: right;
  color: #0e0e0e;
  font-size: 20px;
}

.mob_app {
  width: 100%;
  height: 100%;
}
.fontButton {
  color: var(--black, #0e0e0e);
  text-align: center;
  font-family: gsb;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.btn_new {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 10px 22px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  font-family: GM;
  color: #4a4a4a;
  background: white;
}
/* .btn_new:hover > .svg_thing path {
  fill: white;
} */
.btn_new:hover {
  background: #969696;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
}

/* .btn_new:hover ~ .svg_thing path {
  fill: white;
} */
.mob_app_head_txt {
  font-family: "GBO";
  font-size: 34px;
  line-height: 40px;
  color: #000000;
}

.mob_app_sub_text {
  font-family: "GM";
  font-size: 15px;
  line-height: 24px;
  color: #969696;
}

.mob_app_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 4px;
  font-family: "GSB";
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #0e0e0e;
  width: 58%;
}

.img_thank_you {
  width: 50%;
  height: 50%;
}

.thank_you_head_txt {
  font-family: "GBO";
  font-size: 42px;
  line-height: 49px;
  text-align: center;
  color: #000000;
}

.thank_you_sub_txt {
  font-family: "GM";
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

/* First Section */

.land_first_section_head_txt {
  font-family: GBO;
  font-size: 50px;
  line-height: 74px;
  color: #0e0e0e;
}

.land_first_section_sub_txt {
  font-family: GM;
  font-size: 17px;
  line-height: 32px;
  color: #8d8d8d;
}

.land_first_getStarted,
.land_how_works {
  background: linear-gradient(99.98deg, #2e2e2e 4.09%, #0e0e0e 100.77%);
  border-radius: 5px;
  font-family: GSB;
  font-size: 14px;
  /* line-height: 16px; */
  color: #ffffff;
  text-align: center;
  width: 24%;
  padding: 12px;
  /* display: flex; */
  gap: 28%;
}

.land_first_getStarted {
  text-align: start;
}
/* .apple_logo_btn {
  height: 150px;
  width: 200px;
  padding: 0;
  margin: 0;
} */
.land_first_getStarted:hover {
  color: #ffffff;
}

.land_how_works {
  border: 0.5px solid #0e0e0e;
  box-sizing: border-box;
  color: #0e0e0e !important;
  background: none !important;
}

.land_first_getStarted:focus,
.land_how_works:focus {
  box-shadow: none !important;
}

.land_btn_icon_play,
.land_btn_icon_get {
  color: #fcc314;
  font-size: 20px !important;
  float: left;
}

.land_btn_icon_get {
  transform: rotate(130.24deg);
  float: right !important;
}

.hero_image_first_section {
  width: 100%;
  height: 100%;
}

.mouse_pointer {
  width: 24px;
  height: 28px;
  cursor: pointer;
}

/* Video */
.land_video {
  width: 100%;
  height: 588px;
}

.land_video_parent {
  cursor: pointer;
  padding: 3rem 0rem 3rem 0rem;
}

/* Earning  */
.land_earning_graph {
  width: -webkit-fill-available;
  height: auto;
}

.land_earning_head_text {
  font-family: GBO;
  font-size: 36px;
  color: #000000;
}

.land_earning_sub_text {
  font-family: GM;
  font-size: 17px;
  color: #969696;
}

.carousel-indicators-numbers li {
  text-indent: 0;
  margin: 0 2px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
  line-height: 30px;
  color: #969696;
  font-family: GBO;
  font-size: 18px;
  background: #ffffff;
  border: 1.49922px dashed #969696;
  transition: all 0.25s ease;
  text-align: center;
}

.carousel-indicators-numbers li.active,
.carousel-indicators-numbers li:hover {
  margin: 0 2px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  color: #fcc314;
  border: 1.49922px solid #0e0e0e;
}

.carousel-indicators-numbers {
  bottom: -60px;
  list-style-type: none;
}

/* Join Program */

.land_joinProgram_head_txt {
  font-family: GBO;
  font-size: 38px;
  line-height: 54px;
  color: #000000;
}

.land_joinProgram_sub_txt {
  font-family: GM;
  font-size: 16px;
  line-height: 31px;
  color: #969696;
}

.land_join_getStarted {
  background: linear-gradient(99.98deg, #2e2e2e 4.09%, #0e0e0e 100.77%);
  border-radius: 5px;
  font-family: GSB;
  font-size: 14px;
  /* line-height: 16px; */
  color: #ffffff;
  text-align: center;
  width: 34%;
  padding: 12px;
  /* display: flex; */
  gap: 28%;
  text-align: start;
}

.land_join_getStarted:hover {
  color: #ffffff;
}

.land_joining_icons {
  background: linear-gradient(115.84deg, #ffe185 18.93%, #f7ba00 162.34%);
  border-radius: 3.68842px;
  width: fit-content;
  height: fit-content;
}

.land_join_icon_color {
  color: #0e0e0e;
  font-size: 30px !important;
}

.land_join_icon_txt {
  font-family: GSB;
  font-size: 15px;
  line-height: 22px;
  color: #0e0e0e;
}

.land_join_second_row {
  padding-top: 3rem;
}

.land_join_text_top {
  padding-top: 1rem !important;
}

/* Calculator */
.cal_img {
  /* width: 582px; */
  width: 100%;
  height: 100%;
}

.cal_head_txt {
  font-family: GBO;
  font-size: 38px;
  line-height: 54px;
  color: #000000;
}

.cal_val_display {
  background: #f9f9f9;
  border-radius: 6px;
}

.val_head_txt {
  font-family: GSB;
  font-size: 16px;
  line-height: 19px;
  color: #0e0e0e;
}

.cal_val {
  font-family: GBO;
  font-size: 28px;
  line-height: 33px;
  color: #fcc216;
}

.cal_project_number {
  font-family: GM;
  font-size: 16px;
  line-height: 19px;
  color: #969696;
}

.cal_calculated_value {
  font-family: GSB;
  font-size: 24px;
  line-height: 28px;
  color: #0e0e0e;
}

/* input[type="range"] {
  -webkit-appearance: none;
  -moz-apperance: none;
  cursor: pointer;
  border-radius: 6px;
  height: 6px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0.1, #221e1f),
    color-stop(0.1, #c5c5c5)
  );
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background-color: #e9e9e9;
  border: 1px solid #cecece;
  height: 15px;
  width: 15px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 5px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: yellow;
  border: 1px solid #c5c5c5;
} */

input[type="text"] {
  border: none;
}

.val_border {
  border-right: 1px dashed #000000;
}

.cal_width {
  width: 67%;
}

/* Land Card Carsoul */

.card_carsoul_head_txt {
  font-family: GBO;
  font-size: 38px;
  line-height: 54px;
  color: #000000;
}

.card_carsoul_sub_txt {
  font-family: GM;
  font-size: 16px;
  line-height: 31px;
  color: #969696;
}

.card {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(57.6px);
  border-radius: 5px;
  cursor: pointer;
  height: 55vh;
}

/* .card:hover {
  background: radial-gradient(
    72.73% 135.14% at 20.56% 12.65%,
    #ffe185 0%,
    #f7ba00 100%
  );
  box-shadow: 0px 55.1111px 64.5833px -30.1389px rgba(0, 0, 0, 0.19);
} */

.land_card_text {
  font-family: GM;
  font-size: 14px;
  line-height: 26px;
  color: #0e0e0e;
}

.land_card_profile {
  width: 100%;
  height: 100%;
}

.land_card_profile_name {
  font-family: GSB;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #161616;
}

.land_card_profile_place {
  font-family: GR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #0e0e0e;
}

.card_border_bottom {
  border-bottom: 1px solid #dfdbcf;
}

.card_carsoul_star {
  color: #ffffff !important;
  font-size: 20px;
}

.card_carsoul_rating {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  backdrop-filter: blur(58.3529px);
  border-radius: 1.82353px;
  font-family: GM;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #011108;
}

.card:hover .card_carsoul_rating {
  background: #ffffff;
}

.card:hover .card_border_bottom {
  border-bottom: 1px solid #ffffff;
}

.card:hover .card_carsoul_rating .card_carsoul_star {
  color: #fcc314 !important;
}

.card_carsoul_date {
  font-family: GM;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.card_carsoul-prev,
.card_carsoul-next {
  width: 50px;
  height: 50px;
  left: 1266px;
  top: 2790px;
  background: #ffffff;
  border: none;
  border-radius: 50%;
}

.card_carsoul-prev:hover,
.card_carsoul-next:hover {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.card_carsoul_icon {
  color: #0e0e0e;
  font-size: 20px;
}
#blog {
  background: linear-gradient(
    286.09deg,
    #fff4d1 -4.1%,
    rgba(255, 244, 209, 0.47) 129.05%
  );
  height: 35rem;
}
/*  */

/* .carousel-control-prev,
.carousel-control-next {
  width: 20px;
  height: 20px;
  background-color: none;
  top: calc(-20% -25%);
  opacity: 0.8;
}

.carousel-control-prev {
  left: unset;
  right: 30px;
} */

/* FAQ'S */
.land_faq_image {
  width: 500px;
  height: auto;
}

.land_faq_text {
  font-family: GBO;
  font-size: 38px;
  line-height: 54px;
  color: #000000;
}

.land_faq_right {
  padding-left: 7%;
  align-self: center;
  /* height: 29rem; */
}

.land_acc_item {
  border-bottom: 0.565278px solid rgba(150, 150, 150, 0.4);
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  border: none;
  background: none;
  outline: none;
  font-family: GBO;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.accordion-flush {
  padding-left: 3%;
}

.accordion button .icon {
  /* display: inline-block; */
  position: absolute;
  top: 14px;
  right: 0;
  width: 28px;
  height: 28px;
  border-radius: 22px;
  background: #f2f2f2;
}

.accordion .collapsed .icon {
  background: #f2f2f2 !important;
}

.accordion .icon {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.accordion button .icon::before {
  position: absolute;
  content: "";
  top: 13px;
  left: 9px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  position: absolute;
  content: "";
  top: 9px;
  left: 13px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
  padding-left: 5% !important;
  padding: 1rem 1.25rem 1rem;
  /* background: #f2f2f2; */
  border-radius: 5px;
  font-family: GM;
  font-size: 14px;
  color: #969696;
}

/* ready to partner */
.ready_partner_head_txt {
  font-family: GBO;
  font-size: 28px;
  line-height: 33px;
  color: #0e0e0e;
}

.sign_up_free_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: 0.461385px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 15.6871px 29.5286px -11.0732px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(57.6px);
  border-radius: 5px;
  font-family: GSB;
  font-size: 14px;
  color: #0e0e0e;
  text-align: center;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 15px;
}
.land_sign_free_icon {
  color: #0e0e0e;
  font-size: 20px !important;
  float: right;
  transform: rotate(-47.76deg);
}

/* Referring */
.referring_head_txt {
  font-family: GBO;
  font-size: 38px;
  line-height: 54px;
  color: #0e0e0e;
}

.referring_sub_txt {
  font-family: GM;
  font-size: 16px;
  line-height: 31px;
  color: #969696;
}

.refer_mob_img {
  width: 100%;
  height: 100%;
}

.land_btn_download {
  color: #0e0e0e;
  font-size: 20px !important;
  float: left;
}

.download_app_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: GSB;
  font-size: 14px;
  color: #0e0e0e;
  text-align: center;
  width: 30%;
  padding: 12px;
  gap: 4%;
  display: flex;
}

/* footer */
.land_footer_text_heads {
  font-family: GM;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #1a1a1a;
}

.land_footer_mail {
  display: inline-flex;
  width: auto;
  gap: 10px;
  font-family: GM;
  font-size: 15px;
  line-height: 26px;
  color: #1a1a1a;
  /* border-bottom: 1px solid #ffffff; */
  border-bottom: 0.74961px solid #1a1a1a;
}

.land_footer_mail a {
  font-family: GM;
  font-size: 15px;
  line-height: 26px;
  color: #1a1a1a;
  text-decoration: none !important;
}

.land_footer_btn_icon_get {
  color: #1a1a1a;
  font-size: 18px !important;
  transform: rotate(-47.76deg);
}

.land_footer_number {
  font-family: GM;
  font-size: 15px;
  line-height: 26px;
  color: #1a1a1a;
  text-decoration: none;
  /* border-bottom: 1px solid gray; */
}
.land-foot {
  border-bottom: 1px solid gray;
}

.land_footer_number:hover {
  color: #1a1a1a !important;
}

.land_footer_privacy_policy {
  font-family: GM;
  font-size: 13px;
  line-height: 26px;
  color: #1a1a1a;
  opacity: 0.5;
  cursor: pointer;
}

.land_footer_nav_items,
.land_footer_nav_items_active {
  font-family: GM;
  font-size: 14px;
  line-height: 26px;
  color: #1a1a1a !important;
  text-decoration: none;
}

.land_footer_nav_items_active {
  text-decoration: underline;
}

.display_footer_start {
  display: flex;
  justify-content: flex-start;
}
.display_footer_center {
  display: flex;
  justify-content: center;
}
.display_footer_end {
  display: flex;
  justify-content: flex-end;
}

.land_footer_icons {
  color: #808080;
  /* width: 60%; */
  display: inline-flex;
  width: auto;
  gap: 15px;
  font-size: 40px !important;
  cursor: pointer;
}

.land_footer_icons :hover,
.land_footer_privacy_policy :hover {
  color: #fcc314;
}

.land_footer_icon_with_text {
  font-family: GSB;
  font-size: 14px;
  color: #1a1a1a;
  line-height: 24px;
  padding-left: 15px;
}

.land_footer_divider_line {
  /* border-bottom: 2px solid rgba(255, 255, 255, 0.2); */
  border-bottom: 1.49922px solid rgba(150, 150, 150, 0.2);
}

.land_multiple_icons {
  background: rgba(252, 195, 20, 0.1);
  border-radius: 5.8px;
  width: fit-content;
  height: fit-content;
}

.land_colored_icons {
  color: #f7ba00;
  font-size: 30px;
}
/* user data form */
.form_head_txt {
  font-family: GBO;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
}

.user_form_label {
  font-family: "GM";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-bottom: 10px;
}

.user_form_fileds {
  background: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  font-family: "GSB";
  font-size: 15px;
  line-height: 18px;
  color: #0e0e0e;
}

.form-control:focus {
  background: #ffffff;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  box-shadow: 0px 1px 9px rgba(252, 194, 22, 0.4);
}

.form_OTP_not,
.resend_otp {
  font-family: "GSB";
  font-size: 14px;
  line-height: 16px;
  color: #969696;
}

.resend_otp {
  color: #fcc314;
  cursor: pointer;
  pointer-events: none;
}

.form_signup_btn {
  background: linear-gradient(91.92deg, #ffe185 -10.45%, #f7ba00 115.97%);
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  font-family: "GSB";
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  /* pointer-events: none; */
  display: block;
}
.loader {
  height: 20px;
  width: 20px;
}
.btn:focus {
  box-shadow: none !important;
}

.otp_sent,
.otp_worong {
  font-family: GR;
  font-size: 15px;
  color: green;
}

.otp_worong {
  color: red;
}

.errors {
  color: red !important;
}
.fetaure_1 {
  width: 34px;
}
/* modification */
.waitlist_input_tage {
  padding: 12px;
  width: 58%;
  margin-bottom: 18px;
}
.waitlist_input_tage:hover {
  outline: none;
}
.thankyounew_up {
  background-color: #fff;
}
.waitlist_text {
  font-size: 14px;
  line-height: unset !important;
  font-family: GSB;
  margin-bottom: 0px;
}
.ticket_div {
  background: #fff1c7;
  border-radius: 4px;
  font-family: "GSB";
  color: #000000;
  padding: 14px;
}

.ppl_ahead {
  width: 50%;
  border-right: 7px dotted #fff;
  border-width: 5px;
  font-size: 30px;
}
.ticket_ppl_count {
  font-size: 16px;
  font-family: "GM";
  padding-top: 5px;
  margin-bottom: 0px;
}
.ppl_back {
  width: 50%;
}
.copy_link_sec {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 10px;
  color: #fcc216;
}
.copy_link_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  font-family: "GSB";
  font-size: 14px;
  cursor: pointer;
}
.ull {
  display: none;
}
.copy_link_btn_cover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  padding: 5px;
  display: flex;
  outline: none;
  border: none;
  align-items: center;
  font-family: "GSB";
  font-size: 14px;
  cursor: pointer;
  height: 50px;
}
.tab_lead_data {
  background: #fff6da;
  border-radius: 5px;
  color: #000000;
  border: none;
  font-family: GSB;
  padding: 10px;
}
.active_tab_lead_data {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
}
.lead-tab-content {
  display: none;
}
.lead-tab-active-content {
  display: block;
}
.faqs_section_button {
  justify-content: space-between;
}
.feat_imgs {
  width: 30px;
}
.mobile_disp {
  display: none;
}
.desk_disp {
  display: block;
}
.land_feedback_arrow_prev,
.land_feedback_arrow_next {
  position: absolute;
  top: 50%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3%;
  padding: 0;
  height: 40px;
  text-align: center;
  border: 0;
  /* opacity: 0.5; */
  transition: opacity 0.15s ease;
  background: #000000;
  color: #fff;
  border-radius: 2px;
}
.land_feedback_arrow_prev:hover {
  background: #fdc315;
  color: #0a0a0a;
}
.land_feedback_arrow_next:hover {
  background: #fdc315;
  color: #0a0a0a;
}
.land_feedback_arrow_prev {
  left: -45px;
}
.land_feedback_arrow_next {
  right: -45px;
}
.hero_mob_text {
  display: none;
}
.ready_tobepat {
  background: linear-gradient(
    286.09deg,
    rgb(255, 244, 209) -4.1%,
    rgba(255, 244, 209, 0.47) 129.05%
  );
  padding: 25px;
  text-align: center;
}
.ready-to-partner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rating_box_fixing {
  padding-top: 21%;
}
.rating_box_fixing1 {
  padding-top: 12%;
}
/* For Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .cust_res {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tablet {
    display: none !important;
  }
  .button_centering {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .btn_new {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    font-family: GM;
    color: #4a4a4a;
    background: white;
    margin: 10px;
  }
  .cust_a {
    padding: 7px 0px;
  }
  .cust_div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
  }
  .navbar-nav {
    display: flex !important;
    margin-top: 20px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 101% !important;
  }
  .cust_vline {
    border: none !important;
    padding-right: 0px !important;
  }
  .card {
    height: auto;
  }
  .land_first_getStarted,
  .land_how_works {
    font-size: 13px;
  }
  .hero_image_first_section {
    margin-top: 20px;
  }

  .button_centering {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .ready-to-partner {
    justify-content: center;
    flex-direction: column;
    /* margin-top: 10px; */
  }
  .faq_mob {
    order: 2;
  }
  .card_carsoul_head_txt {
    font-family: GBO;
    font-size: 26px;
    text-align: center;
    line-height: unset;
    color: #000000;
  }

  .land_first_section_sub_txt {
    font-size: 14px;
    line-height: 1.5rem;
  }
  .hero_desktop_carousel {
    display: none;
  }
  .hero_mob_text {
    display: block;
    text-align: center;
    font-size: 34px;
  }
  #blog {
    height: unset;
  }
  .land_feedback_arrow_prev {
    left: unset;
  }
  .land_feedback_arrow_next {
    right: unset;
  }
  .mob_icons {
    display: flex;
    /* position: absolute; */
    /* top: 20px; */
    justify-content: center;
    gap: 10px;
  }
  .land_feedback_arrow_prev,
  .land_feedback_arrow_next {
    width: 8%;
    position: relative;
  }

  .desk_disp {
    display: none;
  }
  .mobile_disp {
    display: block;
  }
  .accordion button .icon {
    right: -24px;
  }
  /* .navbar_light {
    padding: unset;
  } */
  /* #home {
    margin-top: 20%;
  } */
  .waitlist_input_tage {
    width: 100%;
  }
  .faqs_section_button {
    justify-content: unset;
    flex-wrap: wrap;
    gap: 5px;
    /* flex-direction: column; */
  }
  .land_first_getStarted,
  .land_how_works {
    width: 100% !important;
    padding: 10px;
  }

  .land_first_section_head_txt {
    font-size: 26px;
    line-height: 30px;
  }

  /* mobile App Notifications */
  .mob_app_head_txt {
    font-size: 25px;
  }

  .mob_app_btn {
    width: 100%;
  }

  /* .land_first_section_sub_txt {
    font-size: 15px;
  } */

  /* Video */
  .land_video {
    width: 100%;
    height: 100%;
  }

  .land_video_parent {
    padding: 3rem 0rem 0rem 0rem;
  }

  /* Land Joining */
  .land_joinProgram_head_txt {
    font-size: 26px;
    line-height: 40px;
  }
  .land_joinProgram_sub_txt {
    font-size: 11px;
    line-height: 20px;
  }

  .land_join_getStarted {
    width: 60%;
  }

  .land_join_second_row {
    padding-top: 0rem !important;
  }

  .land_joining_icons {
    align-self: center;
  }

  .land_join_col {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-bottom: 10px;
  }

  .land_join_text_top {
    padding-top: 0rem !important;
  }

  /* Calculator */
  .val_border {
    border-right: none !important;
    padding-bottom: 5%;
  }

  .cal_width {
    width: 100%;
  }

  .cal_head_txt {
    font-size: 29px;
    line-height: 40px;
  }

  /* FAQ */
  .land_faq_image {
    width: 100%;
    height: auto;
  }

  .land_faq_right {
    padding-left: 0% !important;
    height: unset;
  }

  .land_faq_text {
    font-size: 34px;
    text-align: center;
    line-height: unset;
  }

  /* ready */
  .sign_up_free_btn {
    gap: 12px;
    /* width: 55%; */
    margin-top: 10px;
  }

  /* referring */
  .referring_head_txt {
    font-size: 34px;
    text-align: center;
  }
  .referring_sub_txt {
    text-align: center;
  }
  .download_app_btn {
    width: 58%;
  }
  .refer_mob_img {
    width: 100%;
    height: 100%;
  }

  /* footer */
  .display_footer_start,
  .display_footer_center,
  .display_footer_end {
    display: flex;
    justify-content: flex-start !important;
  }
}

/* For iPads, Tablets Devices */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .land_first_getStarted,
  .land_how_works {
    width: 100%;
  }
  .nav-item {
    margin-top: 20px;
  }
  .btn_new {
    padding: 6px;
  }

  .cust_div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* flex-direction: column; */
  }
  .navbar-nav {
    display: flex !important;
    margin-top: 20px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    flex-direction: row !important;
    margin-bottom: 15px !important;
  }
  /* .collapse {
    display: none;
  } */
  .cust_vline {
    border: none !important;
  }
  /* mobile App Notifications */
  .mob_app_head_txt {
    font-size: 25px;
  }

  .mob_app_btn {
    width: 40%;
  }

  /* Video */
  .land_video {
    width: 100%;
    height: 100%;
  }

  /* Land Joining */
  .land_join_getStarted {
    width: 50%;
  }

  /* calculator */
  .val_border {
    border-right: none !important;
    padding-bottom: 5%;
  }
  .cal_val_display .row .col-md-6 {
    width: 83%;
  }

  /* FAQ */
  .land_faq_image {
    width: 100%;
    height: 100%;
  }

  /* referring */
  .referring_head_txt {
    font-size: 29px;
  }
  .referring_sub_txt {
    font-size: 14px;
  }
  .download_app_btn {
    width: 100%;
  }
  .refer_mob_img {
    width: 100%;
    height: 100%;
  }
  .land_refer {
    padding-bottom: 20px;
  }
}

/* For Small screen Laptop Devices */
@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .land_first_getStarted,
  .land_how_works {
    width: 100%;
  }
  .tablet {
    display: block !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
  }
  .btn_new {
    padding: 8px 10px;
  }
  /* Land Joining */
  .land_join_getStarted {
    width: 50%;
  }
  .land_join_icon_txt {
    font-size: 14px;
  }
  .cust_vline {
    border: none !important;
  }

  /* FAQ */
  .land_faq_image {
    width: 100%;
    height: 100%;
  }

  /* referring */
  .download_app_btn {
    width: 70%;
  }
}
.div_overflow {
  overflow: hidden;
}
.footer {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  background-color: #f7f7f7;
}
.boxCopyright {
  text-align: center;
  color: #000;
  font-size: 16px;
  font-family: GM;
  line-height: 1;
  font-weight: 500;
  padding: 24px 0px;
  background-color: #ececec;
}
.thankyou_heading,
.thankyou_heading_page {
  font-family: "GB";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: #0a0a0a;
}
.thank_subheading,
.thank_subheading_page {
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #969696;
}
.thanku_line {
  width: 75%;
}
.thankyou_main {
  background: #f9f9f9;
  padding-top: 5%;
  padding-bottom: 5%;
}
.thankyou_heading,
.thankyou_heading_page {
  font-family: "SVEB";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: #0a0a0a;
}

.thankyou_heading {
  font-size: 22px;
  line-height: 36px;
}

.thankyou_heading_page {
  font-size: 36px;
  line-height: 48px;
  font-family: GB;
}
.siteHeaderInner .navbar ul.navbar-nav li a.nav-link {
  color: #9f9898;
}
.cust_a {
  text-decoration: none;
  font-size: 15px;
  font-family: GM;
  color: #000;
}
.cust_a:hover {
  color: #656161;
}
.cust_svg {
  margin-right: 10px;
}
.cust_vline {
  border-right: 1px solid rgba(0, 0, 0, 0.6);
  padding-right: 20px;
}
.cust_btn {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: "#FFF6D9";
}
.custimg {
  cursor: pointer;
}
.thank_line,
.thanku_line {
  background-color: #fdc315;
  height: 3px !important;
  margin: 12px 0px;
}

.thanku_line {
  width: 35%;
}

/* For Desktops, large screens Devices */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
}
.tablet {
  display: none !important;
}
/* ForDesktop or large-size screen Devices */
@media only screen and (min-width: 1201px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
  }
  .tablet {
    display: none !important;
  }
}

/* @media only screen and (min-width: 320px) and (max-width: 575px) {
  .land_first_getStarted,
  .land_how_works {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .land_first_getStarted,
  .land_how_works {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .land_first_getStarted,
  .land_how_works {
    width: 40%;
  }
}

@media only screen and (min-width: 1800px) {
} */
.number_edit_91 {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: #ffe596;
  border-radius: 1px 0px 0px 1px;
}
.PrivacyPolicy {
  font-family: GM;
  font-size: 15px;
  line-height: 26px;
  color: #1a1a1a;
  text-decoration: none;
}
.PrivacyPolicy:hover {
  color: #1a1a1a;
  text-decoration: none;
}
