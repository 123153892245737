/* TermsCondition */
.custom-heading {
    color: var(--dark-grey, #1A1A1A);
    font-family: "GBO";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.mt-4 > h2 {
    color: var(--dark-grey, #1A1A1A);
    font-family: "GSB";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.delteanchor{
    color: var(--dark-grey, #1A1A1A);
    font-family: "GR";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.custom-paragraph {
    color: var(--dark-grey, #1A1A1A);
    font-family: "GR";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.container{
    margin-top: 30px !important;
    max-width: 1076px !important;
    overflow-x: hidden !important;
}
@media screen and (min-width: 390px) and (max-width:1200px){
    .col-md-12{
        padding: 27px !important;
    }
}
@media   (min-width:769px) and (max-width:992px) {
    #tablet{
            display: none !important;
    }
    .col-md-12{
        padding: 57px !important;
    }
    
}