@font-face {
  font-family: "GB";
  src: local("GB"), url(../../Fonts/Gilroy/Gilroy-Black.ttf) format("truetype");
}
@font-face {
  font-family: "GBO";
  src: local("GBO"), url(../../Fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GL";
  src: local("GL"), url(../../Fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GM";
  src: local("GM"), url(../../Fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "GR";
  src: local("GR"),
    url(../../Fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GSB";
  src: local("GSB"),
    url(../../Fonts/Gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "GT";
  src: local("GT"), url(../../Fonts/Gilroy/Gilroy-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "GUL";
  src: local("GUL"),
    url(../../Fonts/Gilroy/Gilroy-UltraLightItalic.ttf) format("truetype");
}

.search_div {
  background: #f4f4f4;
  border-radius: 5px;
  padding: 10px;
  width: 45%;
  display: flex;
  align-items: center;
}

.top_menu_search {
  border: none;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  background-color: #f4f4f4;
  font-family: GM;
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

.top_menu_search:focus {
  outline: none;
}

.profile_img {
  width: 36px;
  height: 36px;
  /* border: 1.2375px solid #fcc314; */
}
