@font-face {
  font-family: "GB";
  src: local("GB"), url(./Fonts/Gilroy/Gilroy-Black.ttf) format("truetype");
}
@font-face {
  font-family: "GBO";
  src: local("GBO"), url(./Fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GL";
  src: local("GL"), url(./Fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GM";
  src: local("GM"), url(./Fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "GR";
  src: local("GR"), url(./Fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GSB";
  src: local("GSB"), url(./Fonts/Gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "GT";
  src: local("GT"), url(./Fonts/Gilroy/Gilroy-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "GUL";
  src: local("GUL"),
    url(./Fonts/Gilroy/Gilroy-UltraLightItalic.ttf) format("truetype");
}

.div_overflow {
  overflow: hidden;
}

/* Nav Bar Common Css */
.img_logo {
  width: 40px;
  height: 31px;
}

.nav-link,
.nav-link-active {
  font-family: GSB;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #1a1a1a !important;
}

.nav-link-active {
  color: #fcc314 !important;
}
